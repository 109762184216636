import './Header.css'

export const EducationLogo = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 140 36'
      className='productLogo'
    >
      <path
        d='M49.0546 30.814L47.4642 33.9146C47.3246 34.189 47.1572 34.3956 46.9619 34.5348C46.77 34.67 46.5608 34.7378 46.3341 34.7378C46.1527 34.7378 45.9732 34.6974 45.7953 34.6171L45.9627 34.0573C46.1056 34.1047 46.2243 34.1287 46.3184 34.1287C46.5382 34.1287 46.7196 34.0097 46.8625 33.772L46.9201 33.6787L45.4604 30.814H46.1877L47.2706 33.0695L48.385 30.814H49.0546Z'
        fill='#0F316C'
      />
      <path
        d='M52.7815 30.814V34.6555H52.1013V33.0091H50.2075V34.6555H49.5273V30.814H50.2075V32.4H52.1013V30.814H52.7815Z'
        fill='#0F316C'
      />
      <path
        d='M53.814 30.814H54.4836V33.5415L56.4507 30.814H57.0838V34.6555H56.4141V31.9335L54.447 34.6555H53.814V30.814Z'
        fill='#0F316C'
      />
      <path
        d='M60.5223 32.6579C60.7558 32.7238 60.9337 32.837 61.0559 32.9982C61.1779 33.1553 61.239 33.3529 61.239 33.5909C61.239 33.9274 61.1204 34.189 60.8833 34.3756C60.646 34.5622 60.3026 34.6555 59.8526 34.6555H58.1157V30.814H59.7532C60.1647 30.814 60.4857 30.8999 60.7159 31.072C60.9461 31.2438 61.0612 31.4871 61.0612 31.8018C61.0612 32.0029 61.0141 32.1768 60.9199 32.3232C60.8291 32.4694 60.6966 32.5811 60.5223 32.6579ZM58.7854 32.4549H59.6957C59.9154 32.4549 60.0828 32.409 60.1979 32.3177C60.313 32.2261 60.3706 32.0907 60.3706 31.9116C60.3706 31.7322 60.313 31.5968 60.1979 31.5055C60.0828 31.4102 59.9154 31.3628 59.6957 31.3628H58.7854V32.4549ZM59.8317 34.1067C60.313 34.1067 60.5537 33.9164 60.5537 33.536C60.5537 33.1628 60.313 32.9762 59.8317 32.9762H58.7854V34.1067H59.8317Z'
        fill='#0F316C'
      />
      <path
        d='M64.7608 34.0573V34.6555H62.0142V30.814H64.6876V31.4122H62.6943V32.411H64.4626V32.9982H62.6943V34.0573H64.7608Z'
        fill='#0F316C'
      />
      <path
        d='M67.0253 30.814C67.3427 30.814 67.6181 30.8689 67.8519 30.9787C68.089 31.0884 68.2704 31.2456 68.396 31.4506C68.5215 31.6554 68.5843 31.8986 68.5843 32.1805C68.5843 32.4584 68.5215 32.7018 68.396 32.9104C68.2704 33.1152 68.089 33.2726 67.8519 33.3823C67.6181 33.4921 67.3427 33.547 67.0253 33.547H66.1987V34.6555H65.5186V30.814H67.0253ZM66.9939 32.9433C67.2904 32.9433 67.5154 32.8774 67.6688 32.7457C67.8222 32.614 67.899 32.4255 67.899 32.1805C67.899 31.9353 67.8222 31.747 67.6688 31.6152C67.5154 31.4835 67.2904 31.4177 66.9939 31.4177H66.1987V32.9433H66.9939Z'
        fill='#0F316C'
      />
      <path
        d='M70.9635 34.7104C70.5937 34.7104 70.2589 34.6261 69.959 34.4579C69.6625 34.2858 69.4287 34.0499 69.258 33.75C69.0905 33.4499 69.0068 33.1114 69.0068 32.7348C69.0068 32.3579 69.0922 32.0194 69.2632 31.7195C69.434 31.4194 69.6677 31.1852 69.9642 31.0171C70.2641 30.845 70.5989 30.7592 70.9687 30.7592C71.2686 30.7592 71.5423 30.814 71.7901 30.9238C72.0377 31.0335 72.2469 31.1927 72.4179 31.4012L71.9784 31.8348C71.7133 31.5347 71.3873 31.3848 71.0001 31.3848C70.749 31.3848 70.524 31.4432 70.3252 31.5604C70.1264 31.6736 69.9711 31.8328 69.8596 32.0378C69.7478 32.2426 69.6922 32.4749 69.6922 32.7348C69.6922 32.9944 69.7478 33.2267 69.8596 33.4317C69.9711 33.6365 70.1264 33.7974 70.3252 33.9146C70.524 34.0279 70.749 34.0848 71.0001 34.0848C71.3873 34.0848 71.7133 33.9329 71.9784 33.6293L72.4179 34.0683C72.2469 34.2768 72.036 34.436 71.7849 34.5457C71.5371 34.6555 71.2634 34.7104 70.9635 34.7104Z'
        fill='#0F316C'
      />
      <path
        d='M73.0547 30.814H73.7243V33.5415L75.6915 30.814H76.3245V34.6555H75.6548V31.9335L73.6877 34.6555H73.0547V30.814Z'
        fill='#0F316C'
      />
      <path
        d='M80.0093 31.4177H78.7955V34.6555H78.1206V31.4177H76.9121V30.814H80.0093V31.4177Z'
        fill='#0F316C'
      />
      <path
        d='M83.3423 34.0573V34.6555H80.5957V30.814H83.2691V31.4122H81.2758V32.411H83.0441V32.9982H81.2758V34.0573H83.3423Z'
        fill='#0F316C'
      />
      <path
        d='M86.7583 31.4177H85.5445V34.6555H84.8697V31.4177H83.6611V30.814H86.7583V31.4177Z'
        fill='#0F316C'
      />
      <path
        d='M89.5884 33.7665H87.7573L87.3964 34.6555H86.6953L88.3433 30.814H89.013L90.6662 34.6555H89.9547L89.5884 33.7665ZM89.3635 33.2067L88.6729 31.5274L87.9875 33.2067H89.3635Z'
        fill='#0F316C'
      />
      <path
        d='M92.6787 30.814H93.3484V33.5415L95.3155 30.814H95.9485V34.6555H95.2789V31.9335L93.3117 34.6555H92.6787V30.814Z'
        fill='#0F316C'
      />
      <path
        d='M100.229 30.814V34.6555H99.5491V33.0091H97.6552V34.6555H96.9751V30.814H97.6552V32.4H99.5491V30.814H100.229Z'
        fill='#0F316C'
      />
      <path
        d='M104.511 30.814V34.6555H103.831V33.0091H101.937V34.6555H101.257V30.814H101.937V32.4H103.831V30.814H104.511Z'
        fill='#0F316C'
      />
      <path
        d='M107.233 34.7104C106.86 34.7104 106.523 34.6261 106.223 34.4579C105.923 34.2858 105.688 34.0499 105.517 33.75C105.346 33.4462 105.261 33.1079 105.261 32.7348C105.261 32.3616 105.346 32.0249 105.517 31.725C105.688 31.4212 105.923 31.1852 106.223 31.0171C106.523 30.845 106.86 30.7592 107.233 30.7592C107.606 30.7592 107.943 30.845 108.243 31.0171C108.543 31.1852 108.778 31.4194 108.949 31.7195C109.12 32.0194 109.205 32.3579 109.205 32.7348C109.205 33.1114 109.12 33.4499 108.949 33.75C108.778 34.0499 108.543 34.2858 108.243 34.4579C107.943 34.6261 107.606 34.7104 107.233 34.7104ZM107.233 34.0848C107.477 34.0848 107.697 34.0279 107.892 33.9146C108.088 33.7974 108.241 33.6365 108.353 33.4317C108.464 33.2232 108.52 32.9907 108.52 32.7348C108.52 32.4786 108.464 32.2481 108.353 32.0433C108.241 31.8348 108.088 31.6736 107.892 31.5604C107.697 31.4432 107.477 31.3848 107.233 31.3848C106.989 31.3848 106.769 31.4432 106.574 31.5604C106.379 31.6736 106.225 31.8348 106.114 32.0433C106.002 32.2481 105.946 32.4786 105.946 32.7348C105.946 32.9907 106.002 33.2232 106.114 33.4317C106.225 33.6365 106.379 33.7974 106.574 33.9146C106.769 34.0279 106.989 34.0848 107.233 34.0848Z'
        fill='#0F316C'
      />
      <path
        d='M113.201 30.814V34.6555H112.532V31.4177H110.633V34.6555H109.963V30.814H113.201Z'
        fill='#0F316C'
      />
      <path
        d='M115.929 34.7104C115.556 34.7104 115.219 34.6261 114.919 34.4579C114.619 34.2858 114.384 34.0499 114.213 33.75C114.042 33.4462 113.957 33.1079 113.957 32.7348C113.957 32.3616 114.042 32.0249 114.213 31.725C114.384 31.4212 114.619 31.1852 114.919 31.0171C115.219 30.845 115.556 30.7592 115.929 30.7592C116.302 30.7592 116.639 30.845 116.939 31.0171C117.238 31.1852 117.474 31.4194 117.645 31.7195C117.816 32.0194 117.901 32.3579 117.901 32.7348C117.901 33.1114 117.816 33.4499 117.645 33.75C117.474 34.0499 117.238 34.2858 116.939 34.4579C116.639 34.6261 116.302 34.7104 115.929 34.7104ZM115.929 34.0848C116.173 34.0848 116.393 34.0279 116.588 33.9146C116.783 33.7974 116.937 33.6365 117.048 33.4317C117.16 33.2232 117.216 32.9907 117.216 32.7348C117.216 32.4786 117.16 32.2481 117.048 32.0433C116.937 31.8348 116.783 31.6736 116.588 31.5604C116.393 31.4432 116.173 31.3848 115.929 31.3848C115.685 31.3848 115.465 31.4432 115.27 31.5604C115.074 31.6736 114.921 31.8348 114.809 32.0433C114.698 32.2481 114.642 32.4786 114.642 32.7348C114.642 32.9907 114.698 33.2232 114.809 33.4317C114.921 33.6365 115.074 33.7974 115.27 33.9146C115.465 34.0279 115.685 34.0848 115.929 34.0848Z'
        fill='#0F316C'
      />
      <path
        d='M121.679 30.814V34.6555H121.01V31.4177H119.566L119.534 32.3781C119.517 32.9159 119.475 33.3529 119.409 33.6896C119.342 34.0224 119.236 34.2768 119.09 34.4524C118.943 34.6243 118.741 34.7104 118.483 34.7104C118.392 34.7104 118.268 34.6919 118.111 34.6555L118.158 34.0354C118.204 34.0499 118.256 34.0573 118.315 34.0573C118.528 34.0573 118.678 33.9256 118.765 33.6622C118.856 33.3951 118.912 32.9762 118.933 32.4055L118.985 30.814H121.679Z'
        fill='#0F316C'
      />
      <path
        d='M122.715 30.814H123.385V33.5415L125.352 30.814H125.985V34.6555H125.315V31.9335L123.348 34.6555H122.715V30.814Z'
        fill='#0F316C'
      />
      <path
        d='M128.696 34.7104C128.327 34.7104 127.992 34.6261 127.692 34.4579C127.395 34.2858 127.162 34.0499 126.991 33.75C126.823 33.4499 126.74 33.1114 126.74 32.7348C126.74 32.3579 126.825 32.0194 126.996 31.7195C127.167 31.4194 127.401 31.1852 127.697 31.0171C127.997 30.845 128.332 30.7592 128.702 30.7592C129.002 30.7592 129.275 30.814 129.523 30.9238C129.771 31.0335 129.98 31.1927 130.151 31.4012L129.711 31.8348C129.446 31.5347 129.12 31.3848 128.733 31.3848C128.482 31.3848 128.257 31.4432 128.058 31.5604C127.859 31.6736 127.704 31.8328 127.593 32.0378C127.481 32.2426 127.425 32.4749 127.425 32.7348C127.425 32.9944 127.481 33.2267 127.593 33.4317C127.704 33.6365 127.859 33.7974 128.058 33.9146C128.257 34.0279 128.482 34.0848 128.733 34.0848C129.12 34.0848 129.446 33.9329 129.711 33.6293L130.151 34.0683C129.98 34.2768 129.769 34.436 129.518 34.5457C129.27 34.6555 128.996 34.7104 128.696 34.7104Z'
        fill='#0F316C'
      />
      <path d='M9.53285 13.0002H0V36H9.53285V13.0002Z' fill='#0F316C' />
      <path
        d='M24.7846 0V25.9984H21.9239V0H12.3911V35.9978H14.4196H21.9239H24.7846H31.2363H34.3176V0H24.7846Z'
        fill='#0F316C'
      />
      <path d='M9.53285 0H0V9.99953H9.53285V0Z' fill='#0F316C' />
      <path
        d='M49.6197 6.97616V7.8136H45.7744V2.43555H49.5172V3.27299H46.7266V4.67128H49.2022V5.49335H46.7266V6.97616H49.6197Z'
        fill='#12152A'
      />
      <path
        d='M55.7643 6.96847V8.95835H54.8853V7.8136H50.9522V8.95835H50.0659L50.0732 6.96847H50.293C50.6152 6.95311 50.84 6.70469 50.9668 6.22323C51.0987 5.73664 51.1792 5.04263 51.2085 4.14116L51.2671 2.43555H55.0245V6.96847H55.7643ZM52.0435 4.23335C52.0192 4.92993 51.9654 5.51129 51.8824 5.97738C51.7995 6.43835 51.6677 6.76872 51.4868 6.96847H54.087V3.28067H52.0801L52.0435 4.23335Z'
        fill='#12152A'
      />
      <path
        d='M56.6685 2.43555H57.606V6.25396L60.3599 2.43555H61.2462V7.8136H60.3087V4.00286L57.5547 7.8136H56.6685V2.43555Z'
        fill='#12152A'
      />
      <path
        d='M67.2394 2.43555V7.8136H66.2872V5.50872H63.6358V7.8136H62.6836V2.43555H63.6358V4.65591H66.2872V2.43555H67.2394Z'
        fill='#12152A'
      />
      <path
        d='M72.0762 6.56896H69.5127L69.0073 7.8136H68.0259L70.3328 2.43555H71.2706L73.5851 7.8136H72.5887L72.0762 6.56896ZM71.7613 5.7853L70.7945 3.43433L69.835 5.7853H71.7613Z'
        fill='#12152A'
      />
      <path
        d='M78.0323 2.43555V7.8136H77.0948V6.31542H75.8277H75.7251L74.773 7.8136H73.7695L74.8682 6.15408C74.5118 6.01579 74.2358 5.7981 74.0405 5.50103C73.8501 5.19883 73.7549 4.83519 73.7549 4.41006C73.7549 4.0003 73.8428 3.64688 74.0186 3.34982C74.1943 3.05275 74.4434 2.82738 74.7656 2.67372C75.0879 2.51494 75.4663 2.43555 75.9009 2.43555H78.0323ZM75.9302 3.28067C75.5395 3.28067 75.2392 3.37543 75.0293 3.56494C74.8242 3.75444 74.7217 4.03103 74.7217 4.39469C74.7217 4.74811 74.8217 5.02212 75.022 5.21677C75.2223 5.40627 75.5127 5.50103 75.8936 5.50103H77.0948V3.28067H75.9302Z'
        fill='#12152A'
      />
      <path
        d='M48.1475 17.1099C47.625 17.1099 47.1539 16.9921 46.7339 16.7565C46.3139 16.5158 45.9843 16.1854 45.7451 15.7654C45.5059 15.3403 45.3862 14.8665 45.3862 14.3441C45.3862 13.8216 45.5059 13.3504 45.7451 12.9304C45.9843 12.5053 46.3139 12.1749 46.7339 11.9393C47.1539 11.6986 47.625 11.5782 48.1475 11.5782C48.6701 11.5782 49.1411 11.6986 49.5611 11.9393C49.9811 12.1749 50.3107 12.5027 50.5499 12.9227C50.7891 13.3427 50.9088 13.8165 50.9088 14.3441C50.9088 14.8716 50.7891 15.3454 50.5499 15.7654C50.3107 16.1854 49.9811 16.5158 49.5611 16.7565C49.1411 16.9921 48.6701 17.1099 48.1475 17.1099ZM48.1475 16.2341C48.4892 16.2341 48.7969 16.1547 49.0704 15.9959C49.3439 15.832 49.5586 15.6066 49.7149 15.3198C49.8713 15.0279 49.9493 14.7026 49.9493 14.3441C49.9493 13.9855 49.8713 13.6629 49.7149 13.376C49.5586 13.0841 49.3439 12.8587 49.0704 12.6999C48.7969 12.536 48.4892 12.4541 48.1475 12.4541C47.8058 12.4541 47.4982 12.536 47.2246 12.6999C46.9511 12.8587 46.7364 13.0841 46.5801 13.376C46.4238 13.6629 46.3457 13.9855 46.3457 14.3441C46.3457 14.7026 46.4238 15.0279 46.5801 15.3198C46.7364 15.6066 46.9511 15.832 47.2246 15.9959C47.4982 16.1547 47.8058 16.2341 48.1475 16.2341Z'
        fill='#12152A'
      />
      <path
        d='M51.9692 11.655H55.961V12.4771H52.9068V13.7986H54.4229C55.0381 13.7986 55.5044 13.9343 55.8219 14.2058C56.1441 14.4721 56.3053 14.8588 56.3053 15.3659C56.3053 15.8986 56.1295 16.3109 55.7779 16.6028C55.4263 16.8897 54.9258 17.0331 54.2764 17.0331H51.9692V11.655ZM54.2251 16.2648C54.5914 16.2648 54.8697 16.1905 55.0601 16.042C55.2506 15.8934 55.3458 15.6783 55.3458 15.3966C55.3458 14.8434 54.9722 14.5669 54.2251 14.5669H52.9068V16.2648H54.2251Z'
        fill='#12152A'
      />
      <path
        d='M59.4571 11.655C59.9013 11.655 60.2872 11.7319 60.6143 11.8855C60.9464 12.0392 61.2003 12.2594 61.3761 12.5462C61.5518 12.8331 61.6397 13.1737 61.6397 13.5681C61.6397 13.9574 61.5518 14.298 61.3761 14.5899C61.2003 14.8767 60.9464 15.097 60.6143 15.2506C60.2872 15.4043 59.9013 15.4811 59.4571 15.4811H58.2998V17.0331H57.3477V11.655H59.4571ZM59.4131 14.636C59.8281 14.636 60.143 14.5438 60.358 14.3594C60.5729 14.175 60.6802 13.9113 60.6802 13.5681C60.6802 13.2249 60.5729 12.9611 60.358 12.7767C60.143 12.5923 59.8281 12.5002 59.4131 12.5002H58.2998V14.636H59.4131Z'
        fill='#12152A'
      />
      <path
        d='M65.6817 15.7884H63.1182L62.6128 17.0331H61.6313L63.9385 11.655H64.876L67.1905 17.0331H66.1944L65.6817 15.7884ZM65.3668 15.0048L64.4 12.6538L63.4405 15.0048H65.3668Z'
        fill='#12152A'
      />
      <path
        d='M70.6719 14.2288C70.9796 14.3313 71.2188 14.4977 71.3897 14.7282C71.5655 14.9536 71.6534 15.2199 71.6534 15.5272C71.6534 15.8499 71.5534 16.1316 71.3531 16.3724C71.1528 16.608 70.8843 16.7898 70.5474 16.9179C70.2153 17.0459 69.8516 17.1099 69.4561 17.1099C69.1144 17.1099 68.7701 17.0613 68.4234 16.964C68.0816 16.8615 67.7642 16.7104 67.4712 16.5107L67.7788 15.7808C68.0034 15.9396 68.2524 16.0625 68.5259 16.1496C68.7994 16.2315 69.0704 16.2725 69.3389 16.2725C69.7246 16.2725 70.0446 16.1931 70.2984 16.0343C70.5573 15.8755 70.6866 15.6579 70.6866 15.3813C70.6866 15.1457 70.5939 14.9664 70.4083 14.8435C70.2226 14.7205 69.9663 14.6591 69.6392 14.6591H68.4453V13.8754H69.566C69.8491 13.8754 70.0713 13.814 70.2325 13.691C70.3936 13.5681 70.4742 13.4016 70.4742 13.1916C70.4742 12.9407 70.3643 12.746 70.1446 12.6077C69.9297 12.4694 69.6539 12.4003 69.3169 12.4003C69.0874 12.4003 68.8507 12.4362 68.6065 12.5079C68.3623 12.5745 68.1329 12.6743 67.918 12.8075L67.6177 12.0008C67.8814 11.8523 68.1597 11.7421 68.4527 11.6704C68.7504 11.5936 69.0459 11.5552 69.3389 11.5552C69.7198 11.5552 70.0688 11.6166 70.3863 11.7396C70.7086 11.8625 70.9649 12.0392 71.1553 12.2697C71.3458 12.4951 71.441 12.7614 71.441 13.0687C71.441 13.3351 71.3726 13.5681 71.2359 13.7679C71.0993 13.9676 70.9111 14.1213 70.6719 14.2288Z'
        fill='#12152A'
      />
      <path
        d='M75.106 17.1099C74.5837 17.1099 74.1124 16.9921 73.6924 16.7565C73.2726 16.5158 72.943 16.1854 72.7036 15.7654C72.4644 15.3403 72.3447 14.8665 72.3447 14.3441C72.3447 13.8216 72.4644 13.3504 72.7036 12.9304C72.943 12.5053 73.2726 12.1749 73.6924 11.9393C74.1124 11.6986 74.5837 11.5782 75.106 11.5782C75.6286 11.5782 76.0998 11.6986 76.5196 11.9393C76.9396 12.1749 77.2692 12.5027 77.5084 12.9227C77.7478 13.3427 77.8673 13.8165 77.8673 14.3441C77.8673 14.8716 77.7478 15.3454 77.5084 15.7654C77.2692 16.1854 76.9396 16.5158 76.5196 16.7565C76.0998 16.9921 75.6286 17.1099 75.106 17.1099ZM75.106 16.2341C75.448 16.2341 75.7556 16.1547 76.0289 15.9959C76.3024 15.832 76.5173 15.6066 76.6734 15.3198C76.8298 15.0279 76.9078 14.7026 76.9078 14.3441C76.9078 13.9855 76.8298 13.6629 76.6734 13.376C76.5173 13.0841 76.3024 12.8587 76.0289 12.6999C75.7556 12.536 75.448 12.4541 75.106 12.4541C74.7643 12.4541 74.4567 12.536 74.1831 12.6999C73.9098 12.8587 73.6949 13.0841 73.5386 13.376C73.3825 13.6629 73.3042 13.9855 73.3042 14.3441C73.3042 14.7026 73.3825 15.0279 73.5386 15.3198C73.6949 15.6066 73.9098 15.832 74.1831 15.9959C74.4567 16.1547 74.7643 16.2341 75.106 16.2341Z'
        fill='#12152A'
      />
      <path
        d='M82.2969 14.2365C82.624 14.3287 82.8731 14.4875 83.044 14.7128C83.2148 14.9331 83.3004 15.2097 83.3004 15.5426C83.3004 16.0138 83.1342 16.38 82.8023 16.6412C82.4702 16.9025 81.9893 17.0331 81.3594 17.0331H78.9277V11.655H81.2203C81.7964 11.655 82.2457 11.7754 82.5679 12.0161C82.8902 12.2569 83.0513 12.5975 83.0513 13.038C83.0513 13.3197 82.9854 13.563 82.8536 13.7678C82.7266 13.9727 82.5409 14.1289 82.2969 14.2365ZM79.8652 13.9522H81.1397C81.4473 13.9522 81.6817 13.8882 81.8428 13.7602C82.004 13.6321 82.0845 13.4426 82.0845 13.1916C82.0845 12.9406 82.004 12.7511 81.8428 12.6231C81.6817 12.4899 81.4473 12.4233 81.1397 12.4233H79.8652V13.9522ZM81.3301 16.2648C82.004 16.2648 82.3409 15.9985 82.3409 15.4658C82.3409 14.9433 82.004 14.6821 81.3301 14.6821H79.8652V16.2648H81.3301Z'
        fill='#12152A'
      />
      <path
        d='M87.7833 15.7884H85.2198L84.7144 17.0331H83.7329L86.0401 11.655H86.9776L89.2921 17.0331H88.296L87.7833 15.7884ZM87.4683 15.0048L86.5015 12.6538L85.542 15.0048H87.4683Z'
        fill='#12152A'
      />
      <path
        d='M93.5416 12.5002H91.8423V17.0331H90.8975V12.5002H89.2056V11.655H93.5416V12.5002Z'
        fill='#12152A'
      />
      <path
        d='M98.2081 16.1956V17.0331H94.3628V11.655H98.1055V12.4925H95.315V13.8908H97.7906V14.7128H95.315V16.1956H98.2081Z'
        fill='#12152A'
      />
      <path
        d='M103.65 11.655V17.0331H102.712V12.5002H100.69L100.647 13.8447C100.622 14.5976 100.563 15.2097 100.471 15.6809C100.378 16.147 100.229 16.503 100.024 16.7488C99.8189 16.9896 99.5357 17.1099 99.1743 17.1099C99.0473 17.1099 98.874 17.0843 98.6543 17.0331L98.7202 16.1649C98.7836 16.1854 98.8569 16.1956 98.9399 16.1956C99.2377 16.1956 99.4478 16.0112 99.5698 15.6425C99.6969 15.2686 99.7749 14.6821 99.8042 13.8831L99.8775 11.655H103.65Z'
        fill='#12152A'
      />
      <path
        d='M107.458 13.4759C108.073 13.4759 108.544 13.6219 108.871 13.9138C109.203 14.2006 109.369 14.6283 109.369 15.1969C109.369 15.791 109.189 16.2469 108.827 16.5644C108.466 16.8769 107.958 17.0331 107.304 17.0331H105.099V11.655H106.037V13.4759H107.458ZM107.26 16.2648C107.631 16.2648 107.917 16.1752 108.117 15.9959C108.317 15.8166 108.417 15.5554 108.417 15.2122C108.417 14.5617 108.031 14.2365 107.26 14.2365H106.037V16.2648H107.26Z'
        fill='#12152A'
      />
      <path
        d='M114.898 11.655V17.0331H113.945V14.7282H111.294V17.0331H110.342V11.655H111.294V13.8754H113.945V11.655H114.898Z'
        fill='#12152A'
      />
      <path
        d='M119.734 15.7884H117.171L116.666 17.0331H115.684L117.991 11.655H118.929L121.243 17.0331H120.247L119.734 15.7884ZM119.42 15.0048L118.453 12.6538L117.493 15.0048H119.42Z'
        fill='#12152A'
      />
      <path
        d='M125.691 11.655V17.0331H124.753V15.5349H123.486H123.383L122.431 17.0331H121.428L122.526 15.3736C122.17 15.2353 121.894 15.0176 121.699 14.7205C121.508 14.4183 121.413 14.0547 121.413 13.6295C121.413 13.2198 121.501 12.8664 121.677 12.5693C121.853 12.2722 122.102 12.0469 122.424 11.8932C122.746 11.7344 123.125 11.655 123.559 11.655H125.691ZM123.588 12.5002C123.198 12.5002 122.898 12.5949 122.688 12.7844C122.482 12.9739 122.38 13.2505 122.38 13.6142C122.38 13.9676 122.48 14.2416 122.68 14.4362C122.88 14.6258 123.171 14.7205 123.552 14.7205H124.753V12.5002H123.588Z'
        fill='#12152A'
      />
      <path
        d='M50.3155 20.8746V26.2526H49.378V21.7197H46.7193V26.2526H45.7817V20.8746H50.3155Z'
        fill='#12152A'
      />
      <path
        d='M56.0914 20.8746V26.2526H55.1539V21.7197H53.1324L53.0884 23.0642C53.0641 23.8171 53.0055 24.4293 52.9126 24.9004C52.8199 25.3666 52.6709 25.7225 52.4658 25.9683C52.2608 26.2091 51.9776 26.3294 51.6162 26.3294C51.4894 26.3294 51.3159 26.3039 51.0962 26.2526L51.1621 25.3844C51.2257 25.405 51.299 25.4151 51.3818 25.4151C51.6798 25.4151 51.8897 25.2307 52.0117 24.862C52.1388 24.4881 52.2168 23.9016 52.2461 23.1026L52.3194 20.8746H56.0914Z'
        fill='#12152A'
      />
      <path
        d='M60.9322 25.0079H58.3687L57.8633 26.2526H56.8818L59.189 20.8746H60.1265L62.441 26.2526H61.4449L60.9322 25.0079ZM60.6173 24.2243L59.6504 21.8734L58.691 24.2243H60.6173Z'
        fill='#12152A'
      />
      <path
        d='M66.6905 21.7197H64.9913V26.2526H64.0464V21.7197H62.3545V20.8746H66.6905V21.7197Z'
        fill='#12152A'
      />
      <path
        d='M73.2994 23.5558C73.2994 24.2627 73.0651 24.8211 72.5963 25.2307C72.1275 25.6406 71.461 25.8634 70.5967 25.8991V26.4677H69.7178V25.8991C68.8536 25.853 68.1847 25.6278 67.711 25.223C67.2422 24.8185 67.0078 24.2627 67.0078 23.5558C67.0078 22.849 67.2422 22.2934 67.711 21.8887C68.1847 21.479 68.8536 21.251 69.7178 21.2049V20.6594H70.5967V21.2049C71.461 21.251 72.1275 21.479 72.5963 21.8887C73.0651 22.2934 73.2994 22.849 73.2994 23.5558ZM70.5967 25.1155C71.1827 25.0694 71.6272 24.9157 71.9298 24.6545C72.2326 24.3882 72.3839 24.0195 72.3839 23.5482C72.3839 23.0771 72.2301 22.7107 71.9225 22.4495C71.6199 22.1883 71.1779 22.0373 70.5967 21.9962V25.1155ZM67.938 23.5558C67.938 24.0221 68.0895 24.3856 68.3921 24.6468C68.6997 24.908 69.1417 25.0643 69.7178 25.1155V21.9962C68.5313 22.0884 67.938 22.6084 67.938 23.5558Z'
        fill='#12152A'
      />
      <path
        d='M76.6729 26.3294C76.1504 26.3294 75.6791 26.2117 75.2593 25.976C74.8393 25.7354 74.5097 25.405 74.2705 24.9849C74.0311 24.5599 73.9116 24.086 73.9116 23.5635C73.9116 23.0411 74.0311 22.57 74.2705 22.1499C74.5097 21.7248 74.8393 21.3944 75.2593 21.1588C75.6791 20.9181 76.1504 20.7977 76.6729 20.7977C77.1952 20.7977 77.6665 20.9181 78.0865 21.1588C78.5063 21.3944 78.8359 21.7222 79.0753 22.1422C79.3145 22.5623 79.4342 23.0361 79.4342 23.5635C79.4342 24.0912 79.3145 24.565 79.0753 24.9849C78.8359 25.405 78.5063 25.7354 78.0865 25.976C77.6665 26.2117 77.1952 26.3294 76.6729 26.3294ZM76.6729 25.4535C77.0146 25.4535 77.3223 25.3743 77.5958 25.2154C77.8691 25.0516 78.084 24.8262 78.2403 24.5393C78.3964 24.2473 78.4747 23.9222 78.4747 23.5635C78.4747 23.2051 78.3964 22.8824 78.2403 22.5955C78.084 22.3035 77.8691 22.0783 77.5958 21.9194C77.3223 21.7555 77.0146 21.6736 76.6729 21.6736C76.331 21.6736 76.0233 21.7555 75.75 21.9194C75.4765 22.0783 75.2616 22.3035 75.1055 22.5955C74.9492 22.8824 74.8711 23.2051 74.8711 23.5635C74.8711 23.9222 74.9492 24.2473 75.1055 24.5393C75.2616 24.8262 75.4765 25.0516 75.75 25.2154C76.0233 25.3743 76.331 25.4535 76.6729 25.4535Z'
        fill='#12152A'
      />
      <path
        d='M82.5962 20.8746C83.0405 20.8746 83.4264 20.9514 83.7535 21.1051C84.0856 21.2587 84.3394 21.479 84.5152 21.7658C84.691 22.0526 84.7789 22.3933 84.7789 22.7876C84.7789 23.177 84.691 23.5175 84.5152 23.8094C84.3394 24.0963 84.0856 24.3165 83.7535 24.4701C83.4264 24.6238 83.0405 24.7006 82.5962 24.7006H81.439V26.2526H80.4868V20.8746H82.5962ZM82.5523 23.8555C82.9675 23.8555 83.2824 23.7633 83.4971 23.5789C83.7121 23.3945 83.8194 23.1309 83.8194 22.7876C83.8194 22.4445 83.7121 22.1806 83.4971 21.9962C83.2824 21.8119 82.9675 21.7197 82.5523 21.7197H81.439V23.8555H82.5523Z'
        fill='#12152A'
      />
      <path
        d='M90.3893 26.2526L90.3819 22.6877L88.6973 25.6379H88.2725L86.5879 22.7338V26.2526H85.687V20.8746H86.4707L88.4996 24.4317L90.5064 20.8746H91.2828L91.2975 26.2526H90.3893Z'
        fill='#12152A'
      />
      <path
        d='M96.1305 25.0079H93.5669L93.0615 26.2526H92.0801L94.3873 20.8746H95.3248L97.6393 26.2526H96.6432L96.1305 25.0079ZM95.8155 24.2243L94.8487 21.8734L93.8892 24.2243H95.8155Z'
        fill='#12152A'
      />
    </svg>
  )
}
